<template>
  <b-card class="p-2 p-lg-4 w-100">
    <div v-if="!passwordWasChange">
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        Сбросить пароль
      </b-card-title>
      <b-card-text class="mb-2">
        Введите ваш новый пароль
      </b-card-text>

      <!-- form -->
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
      >

        <!-- password -->
        <input-password
          ref="password"
          v-model="password"
          v-validate="'required|min:8'"
          label="Пароль"
          name="password"
          :state="password === '' ? null : !errors.has('password')"
          :error="errors.first('password')"
        />

        <!-- password_confirm -->
        <input-password
          v-model="password_confirm"
          v-validate="'required|confirmed:password'"
          data-vv-as="password"
          label="Подтвердить пароль"
          name="password_confirm"
          :state="password_confirm === '' ? null : !errors.has('password_confirm')"
          :error="errors.first('password_confirm')"
        />

        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <!-- submit buttons -->
            <b-button
              class="w-100"
              type="submit"
              variant="primary"
              block
              @click="resetPassword"
            >
              Сбросить пароль
            </b-button>
          </div>
          <div class="col-12 col-lg-6">
            <b-button
              class="w-100"
              type="submit"
              variant="link"
              block
              :to="{name: 'login'}"
              @click="resetPassword"
            >
              Перейти на страницу входа
            </b-button>
          </div>
        </div>
      </b-form>
    </div>
    <div v-else>
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        Пароль успешно изменен
      </b-card-title>
      <b-card-text class="mb-2">
        Перейти на  <a href="/">главную</a>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Auth from '@/api/http/models/auth/Auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      password: '',
      password_confirm: '',
      email: this.$route.query?.email,
      token: this.$route.query?.token,
      passwordWasChange: false,
    }
  },
  computed: {
    ...mapGetters({
      userIsVerified: 'user/isEmailVerified',
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrentUser: 'user/FETCH_CURRENT_USER',
    }),
    async resetPassword() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Auth.reset({
          email: this.email,
          token: this.token,
          password: this.password,
        })

        if (response.status === 'success') {
          this.passwordWasChange = true
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Произошла ошибка',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
